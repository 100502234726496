// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Button, Form, Table,Modal, Input, Select } from 'antd';
import axios from "axios";
import { EditOutlined } from '@ant-design/icons';
import { formatDate } from "contexts/dateFormate";
const { TextArea } = Input;


function Settings() {
    const column = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 180,
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ['descend', 'ascend'],
          fixed: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: 400,
          sorter: (a, b) => a.description.length - b.description.length,
          sortDirections: ['descend', 'ascend'],
          // fixed: 'left',
          elipsis: true,
        },
        {
          title: 'Yearly Price',
          dataIndex: 'monthly_price',
          key: 'monthly_price',
          width: 120,
          sorter: (a, b) => a.monthly_price - b.monthly_price,
          sortDirections: ['descend', 'ascend'],
        },
        // {
        //   title: 'Quarterly Price',
        //   dataIndex: 'quarterly_price',
        //   key: 'quarterly_price',
        //   width: 120,
        //   sorter: (a, b) => a.quarterly_price - b.quarterly_price,
        //   sortDirections: ['descend', 'ascend'],
        // },
        {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 190,
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => (
          <span>{formatDate(record.created_at)}</span>
        )
        },
        {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        sorter: (a, b) => a.status.length - b.status.length,
        sortDirections: ['descend', 'ascend'],
        fixed: 'right',
        render : (text, record) => <Select defaultValue={text === 1 ? "Active" : "Inactive"} style={{ width: 120 }}
        onChange={(value) => handleStatusChange(value, record.id)}>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
        },
        {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        fixed: 'right',
        render: (text, record) => (
            <span>
                <a style={{ marginRight: 16, color: 'blue', fontSize: 16}} onClick={() => handleEdit(record)}><EditOutlined/></a>
            </span>
            ),
      },
      ]
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 50,
  });
  const [sorter, setSorter] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [columns, setColumns] = React.useState(column);
  const [recordId, setRecordId] = React.useState(null);

    const handleEdit = (record) => {
        // console.log(record);
        setRecordId(record.id);
        setModal(true);
        setModalType("edit");
        form.setFieldsValue(record);
    };

    const handleStatusChange = (value, id) => {
        // console.log(value, id);
        setLoading(true);   
        axios.post("https://admin.researchvia.online/web/v1/changeServiceStatus", {
            id: id,status: value === "active" ? 1 : 0}, 
            {headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            }})
            .then((res) => {
            // console.log("res", res);
            getAllServices();
            })
            .catch((err) => {
            console.log(err);
            if(err.response.status === 401){
              localStorage.removeItem('token');
                window.location.href = '/'
            }
            alert(err.response.data.message);
            setLoading(false);
            });
    };

  //open modal
  const handleOpen = () => {
    setModal(true);
    setModalType("add");
  };

  // const handleOk = () => {
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setModal(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    setModal(false);
    setModalType("");
    setConfirmLoading(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    values.quarterly_price = "00";
    // console.log("Received values of form: ", values);
    setConfirmLoading(true);
    if (modalType === "add") {
    // console.log("Received values of form: ", values);
      axios.post("https://admin.researchvia.online/web/v1/addService", values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          // console.log("res", res);
          setModal(false);
          setConfirmLoading(false);
          getAllServices();
          setModalType("");
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 401){
            localStorage.removeItem('token');
              window.location.href = '/'
          }
          alert(err.response.data.message);
          setConfirmLoading(false);
          setModalType("");
        });
    }
    if (modalType === "edit") {
        axios.post("https://admin.researchvia.online/web/v1/updateService", {...values, id: recordId}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            })
            .then((res) => {
            // console.log("res", res);
            setModal(false);
            setConfirmLoading(false);
            getAllServices();
            setModalType("");
            form.resetFields();
            })
            .catch((err) => {
            console.log(err);
            if(err.response.status === 401){
              localStorage.removeItem('token');
                window.location.href = '/'
            }
            alert(err.response.data.message);
            setConfirmLoading(false);
            setModalType("");
            });
        }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:");
  };
  

  
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setPagination(pagination);
    setSorter(sorter);
    setFilter(filters);
  };

    const getAllServices = () => {
        setLoading(true);
    axios.get("https://admin.researchvia.online/web/v1/getServices", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err); 
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        setLoading(false);
      });
    };
  React.useEffect(() => {
    getAllServices();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg :1 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
        <Button type="primary" onClick={handleOpen}
        style={{marginBottom: '30px'}}
        >
          Add New Service</Button>
            
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            // scroll={{ x: 1500 }}
            rowKey={(record) => record.id}
            scroll={{
              y: 480,
            }}
            size='middle'
          />
          <Modal
            title={modalType === "add" ? "Add New Service" : "Edit Service"}
            open={modal}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            //prevent modal from closing when clicked outside modal
            maskClosable={false}
            //prevent modal from closing when clicked on ok button
            // closable={false}
            //remove cancel button
            cancelButtonProps={{ style: { display: "none" } }}
            //remove ok button
            okButtonProps={{ style: { display: "none" } }}
            >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Service Name"
                rules={[{
                    required: true,
                    message: 'Please enter service name!'
                  }]}
              >
                <Input placeholder="Service name must be unique"/>
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[{
                    required: true,
                    message: 'Please enter service description!'
                  }]}
              >
                <TextArea placeholder="Enter Description" showCount maxLength={400} style={{height: 150, resize: 'none'}} />
              </Form.Item>
              <Form.Item
                name="monthly_price"
                label="Yearly Price"
                rules={[{
                    required: true,
                    message: 'Please enter yearly price!'
                  }]}
              >
                <Input  placeholder="Enter price"  type="number"  maxLength={10}/>
              </Form.Item>
              {/* <Form.Item
                name="quarterly_price"
                label="Quarterly Price"
                rules={[{
                    required: true,
                    message: 'Please enter quarterly price!'
                  }]}
              >
                <Input  placeholder="Enter price"  type="number"  maxLength={10}/>
              </Form.Item> */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
      {/* </SimpleGrid> */}
    </Box>
  );
}

export default Settings;
